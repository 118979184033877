import emailjs from '@emailjs/browser';
import React, { useRef } from "react";
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import './validation.css'

const ContactUs = () => {
    const form = useRef();  
    const sendEmail = (e) => {
      e.preventDefault();

    emailjs.sendForm('service_jyn0wq3', 'template_okscz6v', form.current,'UiwmZTy4b_UaN8PJY')
        .then((result) => {
            console.log(result.text);
            alert('Thank you for sending us a message!');
        }, (error) => {
            console.log(error.text);
            alert('Sorry, your message could not be sent. Please try again later.');
        }
        ) 
    }

    return (
        <form ref={form} id="CR-contact-form" onSubmit={sendEmail} className='Contact-form'>
        <label for="CR-contact-form"className="Contact-form title">Contact Us</label>
            <fieldset>
                <label>Name&nbsp;</label>
                <input type="text" name="user_name"/>
                <br />
                <br />
                <label for="user_email">Email&nbsp;&nbsp;</label>
                <input name="user_email" type="text" placeholder="Type your email address here" pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}" required 
                />
                <Form.Control.Feedback type="invalid" for="user_email">
                    Please enter your correctly formatted email address if you would like a response.
                </Form.Control.Feedback>
                <br />
                <br />
                <label>Message&nbsp;</label>
                <textarea rows={3} name="user_message" />

                <input type="hidden" name="user_os" />
                <input type="hidden" name="user_ip" />
                <input type="hidden" name="user_platform" />
                <input type="hidden" name="user_browser" />
                <input type="hidden" name="user_version" />
            </fieldset>
            <div className="g-recaptcha captcha-float" data-sitekey="6LdV5bobAAAAAFdxvqo5_KWv6RKijL1c0yGRQDSO">
            </div>  
            <Button type="submit" className="button-float">Send Message</Button>         
        </form>
        );
    }

    export default ContactUs;