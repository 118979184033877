import React, {Component} from "react";
import 'bootstrap/dist/css/bootstrap.min.css';

class Mission extends  Component{

    render() {        
        return (
            <div className="tab-content">
                <div id="contact" className="container tab-pane active">
                    <div id="banner-div" className="App-banner">
                        <p>Thank you for visiting Cozy Rentals, the home of quality housing that is affordable! Each of our homes is carefully prepared for tenancy with every household system in working order. We conduct regular inspections to make sure the homes remain in good condition for our tenants. Rent increases are predictable and reasonable. Our lease terms for single family units reflect confidence in our tenants and are longer than industry standard with two years minimum and longer terms available after tenancy record is established. When renting month to month to traveling professionals we strive to provide comfortable accomodations with a good selection of affordable short-term housing.</p> 
                    </div>
                </div>
            </div>
        );            
    }
}

export default Mission;