import './App.css';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import 'bootstrap/dist/css/bootstrap.css';
//components
import Photos from './Photos.js';
import Contact from './Contact.js';
import Forms from './Forms.js';
import Mission from './Mission.js';

function App() {
  return (
      <div className="App app-container">
        <div className="App-header app-header-container" >
          <h1>Cozy Rentals
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
          </h1>
          <h2>Serving California and Oregon</h2>
        </div>
        <div id="carousel-div" className='Carousel'>
        <Tabs defaultActiveKey="photos" id="tabbed-nav" className="mb-3 Carousel container Tab">
          <Tab eventKey="photos" title="Highlights">
            <Photos />
          </Tab>
          <Tab eventKey="forms" title="Application Forms">
            <Forms />
          </Tab>
          <Tab eventKey="contact" title="Contact Cozy Rentals">
            <Contact />             
          </Tab>
          <Tab eventKey="mission" title="Mission Statement">
            <Mission />             
          </Tab>
        </Tabs>
      </div>
      <div className="App-footer app-footer-container">
          <br/>
          <p>We advertise vacancies when available. If you are searching for housing please visit  
          &nbsp;
            <a
              className="App-link"
              href="https://www.hcd.ca.gov/about/contact/affordable-housing-rental-directory/index.shtml"
              target="_blank"
              rel="noopener noreferrer"
            >California Affordable Housing</a>
          </p>
          <span>&copy;2021 - 2023 Cozy Rentals - All Rights Reserved</span>
          <br/>
          <img src="logo512.png" className="App-logo App-logo-spin" alt="logo"/>
        </div>
    </div>
  );
}

export default App;
