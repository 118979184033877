import React, {Component} from "react";
import 'bootstrap/dist/css/bootstrap.min.css';

class Forms extends  Component{

    render() {
        return (
            <div className="tab-content">
                <div id="forms" className="container tab-pane active">
                    <br />    
                        <DownloadLink src="CR 2019 Rental Application">DOWNLOAD APPLICATION</DownloadLink>
                    <p>The rental application includes instructions on how to submit it for a property you are interested in. If you are using your phone as a scanner please do not send multiple picture files. Microsoft Lens is a free application that will let you scan directly from your phone and create a PDF file of the entire completed and signed application.</p>
                    <br />
                    <br />
                </div>
            </div>
            );
    }       
}

class DownloadLink extends React.Component {
    render() {
        return (
            <a href={this.props.src} download>{this.props.children}</a>
        )
    }
}
 
export default Forms;