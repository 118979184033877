import React, {Component} from "react";
import Carousel from 'react-bootstrap/Carousel';
import 'bootstrap/dist/css/bootstrap.min.css';

class Photos extends  Component{

    render() {
        return (
        <div className="tab-content background">    
          <div id="photos" className="container tab-pane active">
              <Carousel>
                <Carousel.Item style={{ padding: '0% 8% 0% 8%' }}>
                  <img
                    className="d-block w-100 h-100"
                    width="600px"
                    height="400px"
                    src="Orange tree.jpg"
                    alt="Single Family Home"
                  />
                  <Carousel.Caption>
                    <h3>Natural Surroundings</h3>
                    <p>Enjoy plants and animals nearby, but always convenient to shopping</p>
                  </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="d-block w-100 h-100"
                    width="600px"
                    height="400px"
                    src="kitchen.jpg"
                    alt="Duplex Side A Kitchen"
                  />

                  <Carousel.Caption>
                    <h3>Efficient Kitchens and Baths</h3>
                    <p>Clean and Modern</p>
                  </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="d-block w-100 h-100"
                    width="600px"
                    height="400px"
                    src="vanity.jpg"
                    alt="Duplex Side A Kitchen"
                  />

                  <Carousel.Caption>
                    <h3>Efficient Kitchens and Baths</h3>
                    <p>Clean and Modern</p>
                  </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="d-block w-100 h-100"
                    width="600px"
                    height="400px"
                    src="bdrm_blue.jpg"
                    alt="bedroom"
                  />

                  <Carousel.Caption>
                    <h3>Accommodations for Traveling Professionals</h3>
                    <p>Comfortable rooms in safe surroundings | Fully furnished</p>
                  </Carousel.Caption>
                </Carousel.Item>
              </Carousel>
            </div>
          </div>
          );
      }
  }
        
export default Photos;