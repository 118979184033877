import React, {Component} from "react";
import ContactUs from './ContactUs.js';
import 'bootstrap/dist/css/bootstrap.min.css';

class Contact extends  Component{

    render() {        
        return (
            <div className="tab-content">
                <div id="contact" className="container tab-pane active">
                   <ContactUs /> 
                </div>
            </div>
        );            
    }
}

export default Contact;